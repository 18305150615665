import './typography.css';

const theme = {
  font: {
    primary: `'Tropiline-Bold', 'Prata', serif`,
    secondary: ` 'Recoleta-Regular', 'Average', serif`,
    highlight: ` 'Recoleta-Bold', 'Average', serif`,
    link: `'Roboto Mono', 'Courier', monospace`,
  },
  font_size: {
    xxsmall: 'font-size: 14px; line-height: 3px',
    xsmall: 'font-size: 18px; line-height: 33px',
    small: 'font-size: 20px; line-height: 30px',
    regular: 'font-size: 24px; line-height: 40px; letter-spacing: 2px',
    article: 'font-size: 24px; line-height: 44px; letter-spacing: 0px',
    large: 'font-size: 30px; line-height: 40px',
    larger: 'font-size: 36px; line-height: 48px',
    xlarge: 'font-size: 64px; line-height: 64px; letter-spacing: -4px',
    xlarger: 'font-size: 76px; line-height: 64px; letter-spacing: -4px',
    xxlarge: 'font-size: 96px; line-height: 76px; letter-spacing: -9px',
    xxlarger: 'font-size: 144px; line-height: 64px; letter-spacing: -4px',
  },
  color: {
    white: {
      regular: '#FFFFFF',
      dark: '#F6F6F6',
    },
    black: {
      lighter: '#ABA8AF',
      light: '#564F62',
      regular: '#211E26',
      full: '#000000',
    },
    gray: {
      regular: '#444142',
    },
    purple: {
      light: '#CBACF2',
      regular: '#AB73F1',
      dark: '#66458E',
    },
    green: {
      regular: '#48988E',
    },
    primary: '#FF90A9',
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
};

export default theme;
